<template>
  <div class="content-box">
    <div class="content-header">
      <el-input v-model="queryForm.packageName" maxlength="50" class="input" clearable placeholder="请输入模板名称"></el-input>
      <el-input v-model="queryForm.storeName" maxlength="50" class="input" clearable placeholder="请输入门店名称"></el-input>
      <el-input v-model="queryForm.nickName" maxlength="50" class="input" clearable placeholder="请输入用户名称"></el-input>
      <el-select class="input" clearable v-model="queryForm.moxaVersion" placeholder="请选择设备型号">
        <el-option v-for="item in devicTypeOptions" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-button type="plain" class="cotent-btn" @click="handleSearch">搜索</el-button>
      <el-button type="plain" :loading="moxaOrderAsyncLoading" @click="moxaOrderAsync" class="cotent-btn-big"
        icon="el-icon-refresh">刷新数据</el-button>
    </div>
    <div class="table-list">
      <el-table :data="tableData" style="width: 100%" :max-height="tableMaxheight" :header-cell-style="headerCellStyle"
        border v-loading="loading">
        <el-table-column prop="orderId" align="center" width="" label="订单ID">
        </el-table-column>
        <el-table-column prop="packageName" align="center" width="" label="模板名称">
          <template #default="{ row }">
            <span @click="viewTemplateDetail(row)" style="color: #409EFF;cursor: pointer;">{{row.packageName}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="moxaVersion" align="center" label="设备型号">
          <template #default="{row}">
            {{row.moxaVersion == 0 ? "艾灸大师A01" : "艾灸大师专业版/C01"}}
          </template>
        </el-table-column>
        <el-table-column prop="templateType" align="center" label="模板类型">
          <template #default="{row}">
            {{row.templateType == 0 ? "本地" : "云端"}}
          </template>
        </el-table-column>
        <el-table-column prop="planRunTime" align="center" label="计划运行时长（分钟）">
        </el-table-column>
        <el-table-column prop="realRunTime" align="center" label="实际运行时长（分钟）">
        </el-table-column>
        <el-table-column prop="storeName" align="center" label="门店名称">
        </el-table-column>
        <el-table-column prop="nickName" label="用户名称" width="180" align="center">
        </el-table-column>
        <el-table-column prop="beginDateTime" label="实际开始时间" width="180" align="center">
        </el-table-column>
        <el-table-column prop="endDateTime" label="实际结束时间" width="180" align="center">
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination style="margin-top: 20px" background @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="pageSizes" :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="itemTotal"></el-pagination>

      <el-dialog :title="dialogTitle" :visible.sync="showTemplateDetail" width="700px">
        <TemplateDetail :api="api" :packageData="packageData" :devicTypeId="devicTypeId" type="order" />
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { Watch, Mixins, Component } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";

import TemplateDetail from "./component/templateDetail.vue";

import { MoxibustionStatusEnum } from "@/enum/moxibustion.enum";

import { moxaOrderListApi, moxaOrderViewTemplateApi, moxaOrderAsyncApi } from "@/api/moxibustion";

import { truncateDecimal } from "@/utils/common";

@Component({
  components: {TemplateDetail}
})
  export default class BuildStoreList extends Mixins(loading, tablePagination, Resize) {
    queryForm = {
        packageName: "",
        storeName: "",
        nickName: "",
        moxaVersion: "",
    };

    api = moxaOrderViewTemplateApi;

    tableData = [];
    itemTotal = 0;

    showTemplateDetail = false;
    dialogTitle = "";
    packageData = "";

    moxaOrderAsyncLoading = false;

    devicTypeOptions = [
      { label: "艾灸大师A01", value: 0 },
      { label: "艾灸大师专业版/C01", value: 1 },
    ];
    devicTypeId = 0;

    mounted() {
        this.windowResize(310)
        this.getData();
    }

    moxaOrderAsync() {
      this.moxaOrderAsyncLoading = true;
      moxaOrderAsyncApi().then(() => {
        this.$message.success("正在同步，请稍后点【搜索】查看最新数据。")
      }).finally(() => { this.moxaOrderAsyncLoading = false })
    }

    getData() {
      this.showLoading();
      moxaOrderListApi({
        ...this.queryForm,
        pageNum: this.currentPage,
        pageSize: this.pageSize,
      }).then(res => {
        res.data.list.forEach((el) => {
          el.realRunTime = truncateDecimal(el.realRunTime / 60);
          // el.planRunTime = truncateDecimal(el.planRunTime / 60);
        })
        this.tableData = res.data.list;
        this.itemTotal = res.data.total;
      }).finally(() => {
        this.hideLoading();
      });
    }

    viewTemplateDetail(row){
      this.showTemplateDetail = true;
      this.dialogTitle = row.packageName;
      this.packageData = row.packageData;
      this.devicTypeId = row.moxaVersion;
    }

    //重置分页参数
    resetPages() {
      this.currentPage = 1;
    }
  
    // 搜索
    handleSearch() {
      this.resetPages();
      this.getData();
    }
  }
</script>
<style lang="scss" scoped>
@import "@/styles/app";

.content-box {
  background: #edf0f9;
}

.content-header {
  height: 80px;
  background: #ffffff;
  border-bottom: 1px solid #dfe2e4;
  border-left: 1px solid #dfe2e4;
  border-right: 1px solid #dfe2e4;
  display: flex;
  align-items: center;
}

.input {
  width: 240px;
  height: 40px;
  background: #ffffff;
  border-radius: 2px;
  margin-left: 12px;
}
.cotent-btn {
  margin-left: 12px;
  border: 1px solid #0054ff;
}

.cotent-btn-big {
  margin-left: 40px;
  width: 300px;
  border: 1px dashed #0054ff;
}

.table-list {
  margin-top: 20px;
  background: #ffffff;
  border-radius: 8px;
  padding: 40px 40px;
  overflow: hidden;
  box-sizing: border-box;
  min-height: calc(100% - 101px);
}

.dialogInput {
  width: 100%;
}

.dialog-footer{
  display: flex;
  justify-content: end;
  margin-top: 20px;
}
.store-saas-name{
  color: $primary;
  margin-left: 5px;
}
.plan-dialog-content{
    max-height: calc(100vh - 500px);
    overflow: hidden;
    display: flex;
    .left-step{
        width: 200px;
        height: 100%;
        overflow-y: auto;
    }
    .right-content{
        flex: 1;
    }
}
.tag{
    margin-right: 5px;
}

</style>