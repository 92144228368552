<template>
    <el-table
        border
        max-height="600"
        :data="tableData"
        :header-cell-style="headerCellStyle"
        style="width: 100%">
      <el-table-column
        align="center"
        prop="acupointName"
        label="穴位"
        >
      </el-table-column>
      <el-table-column
      align="center"
        prop="methodName"
        label="灸法"
        >
        <template #default="{row}">
          {{type == "template" ? row.moxibustionName : row.methodName}}
        </template>
      </el-table-column>
      <el-table-column
      align="center"
        prop="time"
        label="时长(分钟)">
      </el-table-column>
    </el-table>
</template>
<script>
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import { truncateDecimal } from "@/utils/common";

@Component
export default class TemplateDetail extends Vue {
  @Prop({ default: "template" }) type;
  @Prop({ default: "" }) api;
  @Prop({ default: "" }) packageData;
  @Prop({ default: 0 }) deviceTypeId;

  loading = false;
  tableData = [];
  // 表头样式
  headerCellStyle() {
    return {
      background: '#F8F9FA', color: '#1A1A1C', textAlign: 'center'
    }
  }

  @Watch("packageData", { immediate: true })
  getTableList(value) {
    let params = {};
    if (this.type == "template") {
      params = { tempId: this.packageData }
    } else {
      params = {moxaVersion: this.deviceTypeId, packageDataItems: JSON.parse(this.packageData)};
    }
    this.loading = true;
    if (!this.api) return;
    this.api(params).then((res) => {
        this.tableData = res.data || [];
    }).finally(() => {
        this.loading = false;
    })
  }
}

</script>